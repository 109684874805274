<template>
  <div>

      <div class="container-fluid mt--7">
          <div class="row">
              <div class="col">
                  <div class="card shadow">
                      <div class="card-header border-0">
                          <div class="row align-items-center">
                              <div class="col"><h3 class="mb-0">{{menuMain.title}} - preusporiadanie</h3></div>
                              <div class="col text-right">

                                  <router-link :to="'/add-taxonomy-item/' + id " class="nav-link  float-right" :href="'/add-taxonomy-item/' + id ">
                                      <template>
                                          <a-button type="primary" size="small">Nová položka slovníka</a-button>
                                      </template>
                                  </router-link>
                              </div>
                          </div>
                      </div>
                      <div class="table-responsive">
                          <div class="table-flush ant-table-wrapper">
                              <div class="ant-table-body">
                                  <table class="ant-table " style="width:100%">
                                      <thead class="ant-table-thead">
                                      <tr>
                                          <th scope="col" colspan="2" style="text-align:left" class="ant-table-column-has-actions ant-table-column-has-sorters">Položky slovníka</th>
                                      </tr>
                                      </thead>
                                      <div class="control_wrapper">
                                          <ejs-treeview id='template' @dataSourceChanged="updateFields" :nodeClicked='nodeclicked' v-model="fields" :nodeTemplate="treeTemplate"   :allowEditing='true' :fields="fields" :allowDragAndDrop='true'>
                                              <ejs-contextmenu id='contentmenutree' target='#template' :items='menuItems' :beforeOpen='beforeopen' :select='menuclick'></ejs-contextmenu>
                                          </ejs-treeview>
                                      </div>


                                  </table></div></div>
                      </div>

                  </div>
              </div>
          </div>
      </div>

      <a-modal title="Zmena názvu menu položky" centered  v-model="nameModal"  @cancel="nameModal = false" v-if="nameModal" @ok="editNameItem">
          <template>
              <div class="mb-4">
                     <a-input alternative="" autofocus
                           input-classes="form-control-alternative"
                           v-model="menu.title"
                  />
              </div>
          </template>
      </a-modal>

  </div>
</template>
<script>
  import {dataService} from "../../_services";
  import Vue from "vue";

  import FormItem from 'ant-design-vue'
  Vue.use(FormItem);
  import {Modal} from "ant-design-vue"
  import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(ContextMenuPlugin);
  import { TreeViewPlugin } from "@syncfusion/ej2-vue-navigations";

  import treeTemplateVue from "./tree-template.vue";
  Vue.use(TreeViewPlugin);
  const columns = [
      {
          title: 'Názov menu',
          dataIndex: 'title',
          sorter: true,
          width: '20%',
      },
      {
          title: 'URL',
          dataIndex: 'url',
      },
      {
          align: 'right',
          scopedSlots: {customRender: 'name'},
      }
  ];

  export default {
      name: "menuitems",
      components: {
          'a-modal': Modal
      },
      props: ['id'],
      data () {
          return {
              fields: {},
              data: [],
              sendata:{},
              editText: '',
              colorsto: JSON.parse(localStorage.colors),
              dragging: false,
              loading: false,
              menuMain:{},
              menu:{},
              nameModal: false,
              menuItemSelected:'',
              menuItems : [
          { text: 'Zmeniť názov', id: 'edittitle' },
          { text: 'Upraviť', id: 'edit' },
          { text: 'Zmazať', id: 'remove' },
      ],
              treeTemplate: function() {
                  return {
                      template: treeTemplateVue
                  };
              },
              columns,
          };
      },
      mounted() {
          this.fetch();

          dataService.axiosFetch('taxonomy',this.id).then(results => {
                  this.menuMain = results;
          });
      },
      methods: {
          nodeclicked: function(args) {

          if (args.event.which === 3) {
              var treeObj = document.getElementById('template').ej2_instances[0];      console.log(treeObj)
              treeObj.selectedNodes = [args.node.getAttribute('data-uid')];
          }
      },
          confirm(e) {
              dataService.axiosDelete(this.$options.name, e).then(results => {
                  if (results) {
                      this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                  }
              });
          },
          updateFields(e)
          {

              this.sendata = e;
              dataService.axiosPost("reordertree", this.sendata).then(results => {
                  if(results.data)
                  {
                      this.$message.success('Položky boli zoradené');
                  }

              })
          },
          cancel() {
          },
          menuclick: function(args) {
          var treevalidate = document.getElementById('template').ej2_instances[0];
          var targetNodeId = treevalidate.selectedNodes[0];

          if (args.item.id == "remove") {

              dataService.axiosDelete('menuitems', targetNodeId).then(results => {
                  if (results) {
                      treevalidate.removeNodes([targetNodeId]);
                  }
              });

          }
          else if (args.item.id == 'edit')
          {
              this.$router.push('/edit-taxonomy-item/'+ targetNodeId + '/' + this.id);
          }
          else if (args.item.id == "edittitle") {


this.nameModal = true;
this.loading = true;
              this.menuItemSelected = targetNodeId;
dataService.axiosFetch('menuitem', targetNodeId).then(results => {
                  this.menu = results[0];
              });


          }
      },
      editNameItem()
      {
          this.nameModal = false;
          dataService.axiosPost('menuitem', this.menu).then(results => {
if(results){
  this.fetch();
}

});
          this.loading = false;
      },
      beforeopen: function(args) {
          var treevalidate = document.getElementById('template').ej2_instances[0];
          var targetNodeId = treevalidate.selectedNodes[0];
          var targetNode = document.querySelector('[data-uid="' + targetNodeId + '"]');
          var contentmenutree = document.getElementById('contentmenutree').ej2_instances[0];
          if (targetNode.classList.contains('remove')) {
              contentmenutree.enableItems(['Remove Item'], false);
          }
          else {
              contentmenutree.enableItems(['Remove Item'], true);
          }
          if (targetNode.classList.contains('rename')) {
              contentmenutree.enableItems(['Rename Item'], false);
          }
          else {
              contentmenutree.enableItems(['Rename Item'], true);
          }
      },
          updateOrder()
          {
              dataService.axiosPost("reordertree", this.data).then(results => {
                  if(results.data)
                  {
                      this.$message.success('Položky boli zoradené');
                  }

              })
          },
          fetch() {
              this.loading = true;
              dataService.axiosFetch('taxtreeitems',this.id).then(results => {
                  this.data = results;
                  this.fields = { dataSource: this.data, id: 'id', text: 'url', url: 'name', child: 'child', selected: 'isSelected', htmlAttributes: 'hasAttribute'  };
                  this.loading = false;
              });
          }

      },
  };
</script>

<style>

  @import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
  @import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";

  ul.ant-pagination {
      margin-top: 0;
      margin-bottom: 1em;
  }

  .ant-pagination a {
      color: #1890ff;
      text-decoration: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
      -webkit-text-decoration-skip: objects;
  }

  .ant-pagination a:focus {
      text-decoration: underline;
      -webkit-text-decoration-skip: ink;
      text-decoration-skip-ink: auto;
  }

  .ant-pagination a:hover {
      color: #40a9ff;
  }

  .ant-pagination a:active {
      color: #096dd9;
  }

  .ant-pagination a:active, a:hover {
      text-decoration: none;
      outline: 0;
  }
  .handle i
  {
      color: #888888;
  }
  .ant-pagination a {
      -ms-touch-action: manipulation;
      touch-action: manipulation;
  }

  .ant-table-pagination.ant-pagination {
      margin-bottom: 40px;
      margin-right: 20px;
  }

  .ant-pagination5-item {
      border: 0;
  }

  .ant-table {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      margin-bottom: 20px;
  }

  .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
      -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      border: 0;
  }

  .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 0;
      margin: 0 3px;
      border-radius: 50% !important;
      width: 36px;
      height: 36px;
      font-size: 0.875rem;
  }

  .ant-pagination5-prev .ant-pagination5-item-link, .ant-pagination5-next .ant-pagination5-item-link {
      width: 36px;
      height: 36px;
      padding: 0;
      margin: 0 3px;
      font-size: 12px;
  }

  .ant-pagination5-item.ant-pagination5-item-active a {
      z-index: 1;
      color: #fff;
      background-color: #5e72e4;
      border-color: #5e72e4;
  }

  .ant-pagination5-item-link.ant-pagination5-disabled a {
      color: #8898aa;
      pointer-events: none;
      cursor: auto;
      background-color: #fff;
      border-color: #dee2e6;
  }
  #template .treeUrl {
      float: right;
      opacity:.5
  }
  #template .nodetext {
      float: left;
  }
  .control_wrapper
  {width:100%;
      display:inline-block;
      overflow:visible;}
</style>
